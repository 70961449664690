import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Image = props => (
  <StaticQuery
    query={graphql`
      query {
        images: allContentfulAsset {
          nodes {
            contentful_id
            title
            fluid(maxWidth: 1440, quality: 95) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => {
      const image = data.images.nodes.find(n => {
        return n.contentful_id === props.contentful_id
      })
      if (!image) {
        return null
      }

      console.log(image)

      //const imageSizes = image.node.childImageSharp.sizes; sizes={imageSizes}
      return (
        <Img
          alt={image.title}
          fluid={image.fluid}
        />
      )
    }}
  />
)

export default Image
