import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { BLOCKS } from "@contentful/rich-text-types"
import { Grid } from "@material-ui/core"
import { graphql } from "gatsby"
import { TweenMax } from "gsap"
import React from "react"
import showdown from "showdown"
import Image from "../components/image"
import { vBandWidth } from "../components/page_layout"
import SEO from "../components/seo"
import { Subtitle, Title, WorkIndex } from "../components/typography"


export default class WorkScreen extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      pageWidth: typeof window !== `undefined` ? window.innerWidth : 0,
    }
  }

  componentDidMount() {
    window.addEventListener("resize", this.onResize)
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.onResize)
  }

  addFullscreenVideoListener() {
    if (document.addEventListener) {
      document.addEventListener(
        "webkitfullscreenchange",
        this.exitHandler,
        false
      )
      document.addEventListener("mozfullscreenchange", this.exitHandler, false)
      document.addEventListener("fullscreenchange", this.exitHandler, false)
      document.addEventListener("MSFullscreenChange", this.exitHandler, false)
    }
  }

  exitHandler = () => {
    this.refs.video.muted = true
    this.removeFullscreenVideoListener()
    console.log("fullscreen")
    if (
      document.webkitIsFullScreen ||
      document.mozFullScreen ||
      document.msFullscreenElement !== null
    ) {
      console.log("fullscreen2")
    }
  }

  removeFullscreenVideoListener() {
    if (document.removeEventListener) {
      document.removeEventListener(
        "webkitfullscreenchange",
        this.exitHandler,
        false
      )
      document.removeEventListener(
        "mozfullscreenchange",
        this.exitHandler,
        false
      )
      document.removeEventListener("fullscreenchange", this.exitHandler, false)
      document.removeEventListener(
        "MSFullscreenChange",
        this.exitHandler,
        false
      )
    }
  }

  onResize = () => {
    if (this.timer) clearTimeout(this.timer)
    this.timer = setTimeout(() => {
      var window_changed =
        window.innerWidth !== window.appWidth &&
        window.innerHeight !== window.appHeight

      if (window_changed) {
        this.setState({
          pageWidth: window.innerWidth,
        })
      }
    }, 500)
  }

  onScroll(scroll) {
    //console.log(scroll);
    //TweenMax.set(this.refs.videoCursor.style, { opacity: 0.5 });
    //console.log(this.refs.videoCursor);

    if (this.state.pageWidth < 960) return

    // const progress = this.state.pageWidth >= 960 ? Math.min(scroll / window.innerHeight, 1) : 0
    const progress = Math.min(scroll / window.innerHeight, 1)
    this.refs.videoCursor.style.marginTop = scroll + "px"

    const blurStr = `blur(${progress * 50}px)`

    TweenMax.killChildTweensOf(this.refs.landing)
    TweenMax.to(this.refs.landing, 0.3, {
      webkitFilter: blurStr,
      filter: blurStr,
      autoRound: false,
      scale: 1 - progress / 5,
      opacity: 1 - progress * 2,
    })
  }

  renderMarkdown(data) {
    const converter = new showdown.Converter()
    const html = converter.makeHtml(data)
    return <div dangerouslySetInnerHTML={{ __html: html }} />
  }

  renderWorkBody(bodyData) {
    const options = {
      renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => {
          const items = []

          const regex = /!\[.*\]\(/
          const str = children + ""
          const strs = str.split(regex)

          // console.log(333333);
          // console.log(str);
          // console.log(strs)

          strs.forEach(item => {
            const index = item.indexOf(")")
            const imgUrl = item.substr(0, index)
            const text = item.substr(index + 1).trim()
            if (imgUrl.length) {
              //console.log(8888, imgUrl, imgUrl.replace('//images.ctfassets.net/', '').split('/')[1])
              items.push(
                <Image
                  contentful_id={
                    imgUrl.replace("//images.ctfassets.net/", "").split("/")[1]
                  }
                />
              )
              // items.push(<img key={`img${index}`} src={imgUrl} />)
              // items.push(<div key={`img${index}`} style={{width: '100%', paddingTop: '30%'}}/>)
            }
            if (text.length)
              items.push(
                <div key={`p${index}`}>
                  <p className="work-body" children={text} />
                </div>
              )
          })

          return <div children={items} />
        },
      },
    }

    return documentToReactComponents(bodyData, options)
  }

  render() {
    const { data } = this.props

    console.log("contentful", data)

    var workIndex = 0
    data.allContentfulWork.edges.forEach((work, index) => {
      if (work.node.slug === data.contentfulWork.slug) workIndex = index
    })

    const videoTransform = "rotateY(-35deg) translateX(-10%) scale(1.2)"

    const renderVideo = ({ style, controls, onClick }) => (
      <video
        ref="video"
        loop
        muted
        autoPlay
        controls={controls}
        playsInline
        src={data.contentfulWork.video.file.url}
        poster={
          data.contentfulWork.video.file.contentType.includes("image") &&
          data.contentfulWork.video.file.url
        }
        style={style}
        onClick={onClick}
      />
    )

    const requestVideoFullscreen = () => {
      var elem = this.refs.video
      elem.muted = false
      if (elem.requestFullscreen) {
        elem.requestFullscreen()
      } else if (elem.mozRequestFullScreen) {
        elem.mozRequestFullScreen()
      } else if (elem.webkitRequestFullscreen) {
        elem.webkitRequestFullscreen()
      } else if (elem.msRequestFullscreen) {
        elem.msRequestFullscreen()
      }

      setTimeout(() => this.addFullscreenVideoListener(), 500)
    }

    console.log(2727, data.contentfulWork.title)
    console.log(2727, data.contentfulWork.previewImage.file.url)

    return (
      <Grid
        ref="container"
        container
        direction="column"
        className="work-container"
        onScroll={e => this.onScroll(e.target.scrollTop)}
      >
        <SEO
          title={data.contentfulWork.title}
          keywords={[
            "3dartist",
            "3dmodeling",
            "3d",
            "photorealism",
            "shading",
            "productstudy",
            "3drender",
            "motiongraphics",
            "motiongraphicsdesign",
            "video",
            "videomaking",
            "3danimation",
            "animation",
          ]}
          video={data.contentfulWork.video.file.url}
          description={data.contentfulWork.description.description}
          image={data.contentfulWork.previewImage.file.url}
        />

        <div>
          <div ref="landing" className="work-landing">
            <Grid
              container
              direction="row"
              alignItems="center"
              justify="space-between"
              style={{ height: "100%" }}
            >
              <Grid item xs={12} md>
                <div
                  style={{
                    padding: "0 40px",
                    //maxWidth: 500,
                  }}
                >
                  <WorkIndex index={workIndex} fontScale={2} />
                  <br />
                  <Title children={data.contentfulWork.title} />
                  <br />
                  <Subtitle children={data.contentfulWork.subtitle} />
                  <br />
                  <div data-hidden="mdUp">
                    {renderVideo({
                      style: { width: "100%" },
                      controls: true,
                    })}
                  </div>
                  <br />
                  <p
                    className="lighter"
                    children={data.contentfulWork.description.description}
                  />
                  <br />
                  <br />
                  <br />
                  {this.renderMarkdown(data.contentfulWork.skills.skills)}
                </div>
              </Grid>

              <Grid item xs={12} md data-hidden="smDown">
                <div
                  style={{
                    //border: '1px solid orange',
                    //marginTop: '10%',
                    perspective: 1500,
                    maxWidth: 800,
                  }}
                >
                  <div
                    style={{
                      zIndex: 10,
                      overflow: "hidden",
                      cursor: "pointer",
                      width: "100%",
                      paddingTop: "56.25%",
                      //background: 'rgba(255, 5, 255, 0.3)',
                      //background: '#555',
                      //border: '1px solid cyan',
                      position: "relative",
                      transform: videoTransform,
                      msTransform: videoTransform,
                      WebkitTransform: videoTransform,
                      boxShadow: "0 0 50px #222",
                    }}
                  >
                    {renderVideo({
                      style: {
                        //border: '1px solid white',
                        position: "absolute",
                        margin: "auto",
                        left: -500,
                        right: -500,
                        top: 0,
                        height: "100%",
                      },
                    })}
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>

          <div style={{ width: "100%", position: "relative" }}>
            <div
              data-hidden="smDown"
              style={{
                height: "100vh",
                //background: 'rgba(255, 0, 50, 0.3)',
                position: "relative",
                textAlign: "right",
                overflow: "hidden",
                paddingTop: "25vh",
                display: "block",
              }}
            >
              {!data.contentfulWork.video.file.contentType.includes(
                "image"
              ) && (
                <div
                  ref="videoCursor"
                  style={{
                    cursor: "pointer",
                    display: "inline-block",
                    width: `calc((100vw - ${vBandWidth * 2}px) / 2)`,
                    height: "50vh",
                    //border: '1px solid red'
                  }}
                  onClick={requestVideoFullscreen}
                />
              )}
            </div>

            <div style={{ position: "relative", display: "block" }}>
              {data.contentfulWork.body &&
                this.renderWorkBody(data.contentfulWork.body.json)}
              {data.contentfulWork.sketchfab && (
                <div className="sketchfab-container">
                  <iframe
                    title="A 3D model"
                    className="sketchfab"
                    src={data.contentfulWork.sketchfab}
                    allow="autoplay; fullscreen; vr"
                  ></iframe>
                </div>
              )}
            </div>
          </div>
        </div>
      </Grid>
    )
  }
}

export const pageQuery = graphql`
  query workQuery($slug: String!) {
    contentfulWork(slug: { eq: $slug }) {
      slug
      title
      subtitle
      description {
        description
      }
      skills {
        skills
      }
      video {
        file {
          url
          contentType
        }
      }
      previewImage {
        file {
          url
          contentType
        }
      }
      body {
        json
      }
      sketchfab
    }

    allContentfulWork(sort: { fields: order, order: ASC }) {
      edges {
        node {
          slug
        }
      }
    }
  }
`
